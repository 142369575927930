/* Example Styles for React Tags*/
#tagInput {
  padding: 40px;
}

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 16px;
  width: 100%;
  border: 1px solid #003b36;
  padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #003b36;
  color: #003b36;
  background: #003b36;
  color: white;
  font-size: 16px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 7px;
  margin-top: 30px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: white;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #003b36;
  cursor: pointer;
}
